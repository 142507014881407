import { get, post } from './request'

export default {

    /**
     * 修改监控配置表
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    update(params) {
        return post('/monitorSetting/updateMonitor', params)
    },
    /**
     * 查询监控配置表
     * @returns {Promise | Promise<unknown>}
     */
    list() {
        return get('/monitorSetting/findMonitor')
    },
    /**
     *监控告警列表
     */
    alarmList(params) {
        return get('/alarm/findLocalAlarmPage', params)
    },
    /**
     *监控历史告警列表
     */
    alarmListHistory(params) {
        return get('/alarm/findLocalAlarmHistoryPage', params)
    },
    /**
     * 告警关注
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    noticeWarm(params) {
        return post('/carorder/editFocus?order_id=' + params.orderId + '&is_focus=' + params.isFocus)
    },
    /**
     * 修改告警备注
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    editRemark(params) {
        return post('/alarm/editRemark', params)
    },
    /**
     * 消除报警
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    deleteWarm(params) {
        return post('/alarm/saveLocalAlarmEliminate?orderNumber=' + params.orderNumber + '&addTime='
            + params.addTime + '&warnType=' + params.warnType + '&typeid=' + params.typeid + '&typefactory=' + params.typefactory)
    },
    /**
     * 报警处理
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    handleWarm(params) {
        return post('/alarm/handleAlarm?id=' + params.id)
    },

    /**
     * 获取订单内设备列表
     */
    getEquipListInOrder(orderNumber) {
        return post('/entity/queryEntityByOrderNumber?orderNumber=' + orderNumber)
    },

    /**
     * 获取车牌号设备列表
     */
    getEquipListInCarNumber(CarNumber) {
        return post('/entity/queryEntityByCarNumber?carNumber=' + CarNumber)
    },

    /**
     * 获取车辆信息
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getCarContent(vin) {
        return post('/carinfo/queryCarByCarVin?carVin=' + vin)
    },
    /**
         * 获取报警历史列表
         * @param params
         * @returns {Promise | Promise<unknown>}
         */
    getAlarmHistoryList(params) {
        return post('/alarm/getAlarmHistoryList', params)
    },
    /**
         * 获取报警历史信息列表
         * @param params
         * @returns {Promise | Promise<unknown>}
         */
    getHisAlarmHistoryList(params) {
        return post('/alarm/getHisAlarmHistoryList?orderNumber=' + params.orderNumber + '&pageNum='
            + params.pageNum + '&pageSize=' + params.pageSize)
    },
    /**
     * 获取报警历史列表
     * @param params
     * @returns {Promise | Promise<unknown>}
     */
    getOrderClashCount() {
        return post('/carorderclash/getCount')
    },
    //设备故障状态更新
    editTrouble(params) {
        return get('/entity/editTrouble', params)
    },
    //优先提醒更新
    editReminder(params) {
        return get('/carorder/editReminder', params)
    },
    //报警信息批量消除
    batchEliminate(params) {
        return get('/alarm/batchEliminate', params)
    },
}
